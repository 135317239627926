// Custom colors
:root {
  --cui-primary: #212631;
  --cui-primary-rgb: 41, 35, 96;
  --cui-link-color: 41, 35, 96;
  --cui-body-color:  41, 35, 96;
}

.btn-primary {
  --cui-btn-color: #fff;
  --cui-btn-bg: #212631;
  --cui-btn-border-color: #212631;
  --cui-btn-hover-color: #fff;
  --cui-btn-hover-bg: #63676E;
  --cui-btn-hover-border-color: #63676E;
  --cui-btn-focus-shadow-rgb: 113, 111, 220;
  --cui-btn-active-color: #fff;
  --cui-btn-active-bg: #63676E;
  --cui-btn-active-border-color: #63676E;
  --cui-btn-active-shadow: inset 0 3px 5px rgba(8, 10, 12, 0.125);
  --cui-btn-disabled-color: #fff;
  --cui-btn-disabled-bg: #212631;
  --cui-btn-disabled-border-color: #212631;
}
