/* You can add global styles to this file, and also import other style files */

// If you want to override variables do it here
$enable-deprecation-messages: false !default;

// Import styles with default layout.
@import "@coreui/coreui/scss/coreui";

// Import Chart.js custom tooltips styles
@import "@coreui/chartjs/scss/coreui-chartjs";

@import 'ngx-toastr/toastr';

@import "~jsoneditor/dist/jsoneditor.min.css";

@import 'custom-colors.scss';

@import "~@ng-select/ng-select/themes/material.theme.css";


// Custom styles for this theme

@font-face {
  font-family: 'HelveticaNeueLight';
  src: url('assets/fonts/HelveticaNeueLight.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

body {
  background-color: var(--cui-tertiary-bg);
  font-family: 'HelveticaNeueLight', sans-serif;
}

.bg-primary {
  --cui-bg-opacity: 1;
  background-color: #212631 !important;
}

.bg-gray {
  --cui-bg-opacity: 1;
  background-color: #4e4e50 !important;
  padding: 6px;
}

.table-light {
  --cui-table-bg: #f3f4f7;
  --cui-table-border-color: #f3f4f7;
  color: var(--cui-table-color);
  border-color: var(--cui-table-border-color);
}

.input-group-prepend {
  white-space: nowrap;
  vertical-align: middle;
  display: flex;
}

.btn-add {
  color: #fff;
  background-color: #212631;
  border-color: #212631;
  width: 100%;
}

.btn-add:hover {
  background-color: #688053;
  border-color: #688053;
  color: white;
}

.btn {
  margin-right: 10px;
}

.btn-info {
  --cui-btn-color: #fff;
  --cui-btn-bg: #212631;
  --cui-btn-border-color: #212631;
  --cui-btn-hover-color: #fff;
  --cui-btn-hover-bg: #212631;
  --cui-btn-hover-border-color: #212631;
  --cui-btn-focus-shadow-rgb: 45, 132, 219;
  --cui-btn-active-color: #fff;
  --cui-btn-active-bg: #212631;
  --cui-btn-active-border-color: #212631;
  --cui-btn-active-shadow: inset 0 3px 5px rgba(8, 10, 12, 0.125);
  --cui-btn-disabled-color: #fff;
  --cui-btn-disabled-bg: #212631;
  --cui-btn-disabled-border-color: #212631;
  padding-left: 50px;
  padding-right: 50px;
}

.btn-outline-info {
  --cui-btn-color: #212631;
  --cui-btn-border-color: #212631;
  --cui-btn-hover-color: #fff;
  --cui-btn-hover-bg: #212631;
  --cui-btn-hover-border-color: #212631;
  --cui-btn-focus-shadow-rgb: 51, 153, 255;
  --cui-btn-active-color: #fff;
  --cui-btn-active-bg: #212631;
  --cui-btn-active-border-color: #212631;
  --cui-btn-active-shadow: inset 0 3px 5px rgba(8, 10, 12, 0.125);
  --cui-btn-disabled-color: #212631;
  --cui-btn-disabled-bg: transparent;
  --cui-gradient: none;
  padding-left: 50px;
  padding-right: 50px;
}

.form-check-input:checked {
  background-color: #212631;
  border-color: #212631;
}

.col-form-label {
  color: #212631;
  font-weight: 700;
}

.form-group {
  margin-bottom: 1rem;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

h2 {
  color: #212631;
  font-weight: 700;
  font-size: 20px;
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #212631;
  line-height: 1em;
  margin: 10px 0 20px;
}

.wrapper {
  width: 100%;
  @include ltr-rtl("padding-left", var(--cui-sidebar-occupy-start, 0));
  @include ltr-rtl("padding-right", var(--cui-sidebar-occupy-end, 0));
  will-change: auto;
  @include transition(padding .15s);
}

a {
  color: #212631;
}

a.disabled {
  pointer-events: none;
  cursor: default;
}

json-editor>div {
  height: 100%;
}

.popover-header {
  padding: .50rem .75rem;
  margin-bottom: 0;
  font-size: .875rem;
  background-color: #f7f7f700;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(.3rem - 1px);
  border-top-right-radius: calc(.3rem - 1px);
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  background-color: #212631;
  border-radius: 2px;
  color: #fff;
  padding: 2px 5px;
  margin: 0 0.4375em 0.4375em 0;
}

.header>.container-fluid,
.sidebar-header {
  min-height: calc(4rem + 1px); // stylelint-disable-line function-disallowed-list
}

.sidebar-brand-full {
  margin-left: 3px;
}

.sidebar-header {
  .nav-underline-border {
    --cui-nav-underline-border-link-padding-x: 1rem;
    --cui-nav-underline-border-gap: 0;
  }

  .nav-link {
    display: flex;
    align-items: center;
    min-height: calc(4rem + 1px); // stylelint-disable-line function-disallowed-list
  }
}

.sidebar-toggler {
  @include ltr-rtl("margin-left", auto);
}

.sidebar-narrow,
.sidebar-narrow-unfoldable:not(:hover) {
  .sidebar-toggler {
    @include ltr-rtl("margin-right", auto);
  }
}

.header>.container-fluid+.container-fluid {
  min-height: 3rem;
}

.wrap {
  display: flex;
  justify-content: space-between;
}

.card-header {
  background-color: #212631;
  color: white;
  font-weight: 600;
}

.simple-card-body {
  border-bottom: 7px solid #212631;
  border-radius: 10px
}

.card-footer {
  border-top: 0;
  background-color: #fff;
}

.card {
  --cui-card-border-color: #fff;
}

.footer {
  min-height: calc(3rem + 1px); // stylelint-disable-line function-disallowed-list
}

@if $enable-dark-mode {
  @include color-mode(dark) {
    body {
      background-color: var(--cui-dark-bg-subtle);
    }

    .footer {
      --cui-footer-bg: var(--cui-body-bg);
    }
  }
}

// breadcrumb-item

.breadcrumb-bkg {
  background-color: #212631;
}

.breadcrumb-item.active {
  color: white;
  padding-left: 85px;
  font-size: 20px;
  font-weight: bolder;
}

// If you want to add custom CSS you can put it here.
// custom .chartjs-tooltip-body-item padding
.chartjs-tooltip-body-item>td {
  padding-bottom: 0 !important;
  padding-top: 0 !important;
  font-size: smaller;
}

// navigation
.nav-underline-border {
  --cui-nav-underline-border-link-active-color: var(--cui-primary);
}


// custom tweaks for scrollbar styling (wip)
// scrollbar tinkering

@supports (scrollbar-width: thin) {
  * {
    scrollbar-width: thin;
    scrollbar-color: #444 transparent;
  }
}

//::-webkit-scrollbar {
//  width: .7em;
//  height: .7em;
//}

::-webkit-scrollbar-track {
  background: var(--cui-body-bg, #fff);
  border-radius: 100vw;
  margin-block: .2em;
}

::-webkit-scrollbar-thumb {
  background: #999;
  border: .15em solid var(--cui-body-bg, #fff);
  border-radius: 100vw;
}

::-webkit-scrollbar-thumb:hover {
  background: #444;
}

.dark-theme::-webkit-scrollbar-thumb {
  background: var(--cui-gray-600, #444);
}

.dark-theme::-webkit-scrollbar-thumb:hover {
  background: var(--cui-gray-400, #999);
}

.ng-scroll-content {
  display: flex !important;
}

.ng-scrollbar:not(.overflow) .ng-scrollbar-wrapper[verticalused="false"] {

  //background-color: #e797a5;
  .ng-scroll-viewport {
    display: flex;
  }
}


// custom calendar today cell color
.calendar-cell.today {
  --cui-calendar-cell-today-color: var(--cui-info) !important;
}

// custom select week cursor pointer
.select-week .calendar-row.current {
  cursor: pointer;
}

// Toast - Customization

.toast-container.toast-top-full-width .ngx-toastr,
.toast-container.toast-bottom-full-width .ngx-toastr {
  width: 100%;
  margin-left: 0;
  margin-right: 0;
  padding: 5px 50px;
  text-align: center;
}

.toast {
  background-color: #768591;
  pointer-events: auto;
}

.toast-success {
  background-color: #69c14c
}

.toast-error,
.toast-warning {
  background-color: #e1085a;
}

.toast-info {
  background-color: #f29934
}

.input-disabled {
  background-color: #f0f0f0;
  cursor: not-allowed;
}

.modal-function .modal-content {
  width: 700px;
}

// Examples
// We use those styles to show code examples, you should remove them in your application.
/* stylelint-disable declaration-no-important, scss/selector-no-redundant-nesting-selector */
$enable-deprecation-messages: false;
/* stylelint-disable-line scss/dollar-variable-default */

.example {
  &:not(:first-child) {
    margin-top: 1.5rem;
  }

  .tab-content {
    background-color: var(--#{$prefix}tertiary-bg);
    //background-color: rgba(var(--#{$prefix}tertiary-bg-rgb), var(--#{$prefix}bg-opacity, 1)) !important;
  }

  &+p {
    margin-top: 1.5rem;
  }

  // Components examples
  .preview {
    +p {
      margin-top: 2rem;
    }

    >.form-control {
      +.form-control {
        margin-top: .5rem;
      }
    }

    >.nav+.nav,
    >.alert+.alert,
    >.navbar+.navbar,
    >.progress+.progress {
      margin-top: 1rem;
    }

    >.dropdown-menu {
      position: static;
      display: block;
    }

    > :last-child {
      margin-bottom: 0;
    }

    // Images
    >svg+svg,
    >img+img {
      margin-left: .5rem;
    }

    // Buttons
    .col>.btn,
    .col-auto>.btn,
    .d-md-block>.btn,
    >.btn,
    >.btn-group {
      margin: .25rem .125rem;
    }

    >.btn-toolbar+.btn-toolbar {
      margin-top: .5rem;
    }

    .btn-fw {
      width: 100%;
    }

    // List groups
    >.list-group {
      max-width: 400px;
    }

    >[class*="list-group-horizontal"] {
      max-width: 100%;
    }

    // Navbars
    .fixed-top,
    .sticky-top {
      position: static;
      margin: -1rem -1rem 1rem;
    }

    .fixed-bottom {
      position: static;
      margin: 1rem -1rem -1rem;
    }

    @include media-breakpoint-up(sm) {

      .fixed-top,
      .sticky-top {
        margin: -1.5rem -1.5rem 1rem;
      }

      .fixed-bottom {
        margin: 1rem -1.5rem -1.5rem;
      }
    }

    // Pagination
    .pagination {
      margin-top: .5rem;
      margin-bottom: .5rem;
    }

    .docs-example-modal {
      .modal {
        position: static;
        display: block;
      }
    }
  }
}